import { useCallback, useEffect, useRef, useState } from "react";
import InputMaterial from "../material/Input";
import { useA11yToggleButton } from "@accessible/toggle-button";
import Image from "next/image";
import classNames from "classname";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Link from "next/link";

const DELAY_TIME = 500;

export default function Search(props) {
	const searchRef = useRef(null);
	const [query, setQuery] = useState("");
	const [active, setActive] = useState(false);
	const [results, setResults] = useState([]);
	const [controller, setController] = useState(null);
	const timerRef = useRef(null);

	const searchEndpoint = (query) => `/api/search/przyjaznedeklaracje?q=${query}`;

	const debouncedSearch = useCallback(
		(query) => {
			if (controller) {
				controller.abort();
			}
			const newController = new AbortController();
			setController(newController);
			if (query.length) {
				setTimeout(() => {
					fetch(searchEndpoint(query), { signal: newController.signal })
						.then((res) => res.json())
						.then((res) => {
							setResults(res.results);
						});
				}, DELAY_TIME);
			} else {
				setResults([]);
			}
		},
		[controller],
	);

	useEffect(() => {
		return () => {
			if (controller) {
				controller.abort();
			}
		};
	}, [controller]);

	const handleChange = useCallback(
		(event) => {
			const query = event.target.value;
			setQuery(query);
			clearTimeout(timerRef.current);
			timerRef.current = setTimeout(() => {
				debouncedSearch(query);
			}, DELAY_TIME);
		},
		[debouncedSearch],
	);

	const onFocus = useCallback(() => {
		setActive(true);
		window.addEventListener("click", onClick);
	}, []);

	const onClick = useCallback((event) => {
		if (searchRef.current && !searchRef.current.contains(event.target)) {
			setActive(false);
			window.removeEventListener("click", onClick);
		}
	}, []);

	const ref = useRef(null);
	const [muted, setMuted] = useState(false);
	const a11yProps = useA11yToggleButton(ref, {
		active: muted,
		onChange: setMuted,
	});

	const [visible, setVisible] = useState(false);
	const show = () => setVisible(true);
	const hide = () => setVisible(false);

	function searchButton() {
		query == "" ? show() : (window.location.href = "/wyszukiwarka?wyniki=" + query);
	}

	return (
		<>
			<button ref={ref} {...a11yProps} className="pd-search__button">
				{muted ? (
					"Anuluj"
				) : (
					<>
						<span className="sr-only">Otwórz wyszukiwarkę"</span>

						<span className="desktop">
							<Image unoptimized 
								alt=""
								src="https://przyjazne-deklaracje.pl/resources/images/icons/general/search/search-icon.svg"
								width={24}
								height={24}
							/>
						</span>
						<span className="contrast">
							<Image unoptimized 
								alt=""
								src="https://przyjazne-deklaracje.pl/resources/images/icons/general/search/search-icon--contrast.svg"
								width={24}
								height={24}
							/>
						</span>
						<span className="mobile">
							<Image unoptimized 
								alt=""
								src="https://przyjazne-deklaracje.pl/resources/images/icons/general/search/search-icon--mobile.svg"
								width={24}
								height={24}
							/>
						</span>
					</>
				)}
			</button>

			<Tippy content="Pole wyszukiwarki nie może być puste" visible={visible} onClickOutside={hide}>
				<div
					className={classNames("pd-search__container", {
						active: muted,
					})}
					ref={searchRef}
				>
					<div className="pd-search__input">
						<InputMaterial
							onChange={handleChange}
							onFocus={onFocus}
							placeholder="Wpisz szukaną frazę..."
							type="text"
							name={props.name}
							value={query}
							onKeyPress={(event) => {
								if (event.key === "Enter" || event.keyCode === 13) {
									query == "" ? show : (window.location.href = "/wyszukiwarka?wyniki=" + query);
								}
							}}
						>
							Wpisz szukaną frazę...
						</InputMaterial>
					</div>

					<div className="pd-search__button__container">
						{query != "" && (
							<button
								className="pd-search__button__delete"
								aria-label="Skasuj aktualną wartość w polu"
								onClick={() => {
									setQuery("");
									setResults("");
									setActive(false);
								}}
							>
								<span className="close"></span>
							</button>
						)}

						<button
							className="pd-search__button__search"
							aria-label="Wyszukaj"
							onClick={searchButton}
						>
							<span className="desktop">
								<Image unoptimized 
									alt=""
									src="https://przyjazne-deklaracje.pl/resources/images/icons/general/search/search-icon.svg"
									width={24}
									height={24}
								/>
							</span>
							<span className="contrast">
								<Image unoptimized 
									alt=""
									src="https://przyjazne-deklaracje.pl/resources/images/icons/general/search/search-icon--contrast.svg"
									width={24}
									height={24}
								/>
							</span>
							<span className="mobile">
								<Image unoptimized 
									alt=""
									src="https://przyjazne-deklaracje.pl/resources/images/icons/general/search/search-icon--mobile.svg"
									width={24}
									height={24}
								/>
							</span>
						</button>
					</div>

					{active && results.length > 0 && (
						<ul className="pd-search__wrapper">
							{results.map(({ title, description, fullUrl, index }) => (
								<li key={index} className="pd-search__list">
									<Link className="pd-search__link" href={fullUrl}>
										<h3 className="pd-search__title">{title}</h3>
										<p className="pd-search__description">{description}</p>
									</Link>
								</li>
							))}
						</ul>
					)}
				</div>
			</Tippy>

			<div></div>
		</>
	);
}
