import { useEffect } from "react";

function debounce(func, wait = 20, immediate = true) {
	let timeout;
	return function () {
		let context = this,
			args = arguments;
		let later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		let callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

const HeaderScript = () => {
	useEffect(() => {
		if (typeof window !== "undefined") {
			const handleScroll = debounce(() => {
				const body = document.querySelector("body");
				const header = document.querySelector("header");

				if (window.scrollY > 300) {
					body.classList.add("pd-navigation__body--margin-top");
					header.classList.add("fixed");
				} else {
					body.classList.remove("pd-navigation__body--margin-top");
					header.classList.remove("fixed");
				}
			}, 10);

			window.addEventListener("scroll", handleScroll);

			return () => {
				window.removeEventListener("scroll", handleScroll);
			};
		}
	}, []);

	return null;
};

export default HeaderScript;
