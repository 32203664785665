import { useRouter } from "next/router";
import { useIntl } from "react-intl";
import { NextSeo } from "next-seo";
import Variables from "../variables/variables.json";
import { useMemo } from "react";

export default function SEO(props) {
	const router = useRouter();
	const intl = useIntl();

	let hostName = "https://przyjazne-deklaracje.pl";
	let routerUrl = router.asPath;
	let routerLocale = router.locale;
	let RouterRedirect = props.routerRedirect;

	let Miasto;
	let Podatek;

	let miastoJSON;
	let witryna;

	let heroImg;

	if (!props.blog) {
		Miasto = props.miasto;
		Podatek = props.podatek;

		miastoJSON = Variables.miasta[Miasto];
		witryna = miastoJSON.witryna;

		heroImg = miastoJSON.slider[Podatek].header.images;
	}

	let dateISO8601 = props.date + "T" + props.hours + "Z";

	// Memoizowanie tłumaczeń
	const seoTitle = useMemo(() => {
		return props.blog
			? props.seoTitle
			: intl.formatMessage({
					id: "miasta." + Miasto + ".SEO." + Podatek + ".title",
			  });
	}, [props.blog, props.seoTitle, intl, Miasto, Podatek]);

	const seoDescription = useMemo(() => {
		return props.blog
			? props.seoDescription
			: intl.formatMessage({
					id: "miasta." + Miasto + ".SEO." + Podatek + ".description",
			  });
	}, [props.blog, props.seoDescription, intl, Miasto, Podatek]);

	const siteName = useMemo(() => {
		return props.blog
			? "Przyjazne Deklaracje"
			: intl.formatMessage({
					id: "miasta." + Miasto + ".siteName",
			  });
	}, [props.blog, intl, Miasto]);

	return (
		<>
			{props.blog ? (
				<NextSeo
					title={seoTitle}
					description={seoDescription}
					canonical={hostName + routerUrl}
					openGraph={{
						site_name: siteName,
						type: "website",
						locale: routerLocale,
						title: seoTitle,
						description: seoDescription,
						images: [
							{
								url: props.imagesUrl,
							},
						],
						type: "article",
						article: {
							authors: [props.author],
							publishedTime: dateISO8601,
						},
					}}
					noindex={false}
					nofollow={false}
				/>
			) : (
				<NextSeo
					title={seoTitle}
					description={seoDescription}
					canonical={hostName + routerUrl}
					openGraph={{
						site_name: siteName,
						type: "website",
						locale: routerLocale,
						url: witryna ? hostName + RouterRedirect : hostName + routerUrl,
						title: seoTitle,
						description: seoDescription,
						images: [
							{
								url: heroImg.mobile,
							},
						],
					}}
					noindex={Variables.miasta[Miasto].test}
					nofollow={Variables.miasta[Miasto].test}
				/>
			)}
		</>
	);
}
